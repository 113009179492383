import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { sentenceCase } from 'change-case';
// @mui
import {
  Grid,
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  TableRow,
  MenuItem,
  TableBody,
  TableHead,
  TableCell,
  Container,
  Typography,
  TextField,
  TableContainer,
  TablePagination,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import axios from 'axios';
import { Oval } from 'react-loader-spinner';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { BaseURL, BaseURLImage } from '../common/Base_Url';

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

export default function PromotionsPage() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [time, setTime] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState([]);
  const [categoryName, setCategoryName] = useState('');
  const [categoryImage, setCategoryImage] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [query, setQuery] = useState('');

  const [TABLE_DATA, setAboutContent] = useState([]);

  useEffect(() => {
    GetContent();
  }, []);

  const GetContent = () => {
    setLoading(true);

    const TOKEN = localStorage.getItem('currentUserAccessToken');
    const BODY = {};

    fetch(`${BaseURL}getPromotions`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setLoading(false);
        setAboutContent(responseJson.messages);
      })
      .catch((error) => {
        setLoading(false);
        alert(JSON.stringify(error));
      });
  };

  const handleClick = () => {
    if (image.length === 0) {
      alert('Please Select Promotion Image');
    } else if (title === '') {
      alert('Please Enter Title');
    } else if (description === '') {
      alert('Please Enter Description');
    } else {
      uploadImage();
    }
  };

  const uploadImage = async (e) => {
    setLoading(true);

    const TOKEN = localStorage.getItem('currentUserAccessToken');

    const formData = new FormData();
    formData.append('photo', image.target.files[0]);

    axios
      .post(`${BaseURL}uploadIMG`, formData, {
        headers: {
          Accept: 'application/json',
          'Content-type': 'multipart/form-data',
          Authorization: `Bearer ${TOKEN}`,
        },
      })
      .then((response) => {
        setLoading(false);

        if (response.data.message === 'Image Uploaded') {
          uploadData(response.data.url);
        } else {
          alert('Something went wrong please try again in a while');
        }
      })
      .catch((e) => {
        setLoading(false);
        alert(JSON.stringify(e));
      });
  };

  const uploadData = (IMAGE) => {
    const Title = title;
    const Description = description;

    setLoading(true);

    const TOKEN = localStorage.getItem('currentUserAccessToken');

    fetch(`${BaseURL}createPromotion`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: `Bearer ${TOKEN}`,
      },
      body: JSON.stringify({
        image: IMAGE,
        title: Title,
        description: Description,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setLoading(false);

        if (responseJson.status === 200) {
          setTitle('');
          setDescription('');
          GetContent();
        } else {
          alert('Something Went Wrong Please Try Again');
          GetContent();
        }
      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });
  };

  const handleDelete = (ID) => {
    setLoading(true);

    const TOKEN = localStorage.getItem('currentUserAccessToken');

    const BODY = {
      id: ID,
    };

    fetch(`${BaseURL}deletePromotion`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: `Bearer ${TOKEN}`,
      },
      body: JSON.stringify(BODY),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setLoading(false);

        if (responseJson.status === 200) {
          GetContent();
        } else {
          alert('Something Went Wrong Please Try Again');
          GetContent();
        }
      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const search = (data) => {
    return data;
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - search(TABLE_DATA).length) : 0;
  const isNotFound = !TABLE_DATA.length && !!TABLE_DATA;

  return (
    <>
      <Helmet>
        <title> Promotions | Nova Auto Spa </title>
      </Helmet>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Promotions
        </Typography>
        {loading === true ? (
          <Oval
            height={30}
            width={30}
            color="#0F70F5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={'true'}
            ariaLabel="oval-loading"
            secondaryColor="#2e3a59"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        ) : null}
      </Stack>

      <Card style={{ padding: 20 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <h4>Select Promotion Image</h4>
            <div style={{ backgroundColor: '#dddddd', borderRadius: 10, padding: 20, marginBottom: 20 }}>
              <input accept="image/*" id="contained-button-file" type="file" name="file" onChange={setImage} />
            </div>
            <TextField
              style={{ width: '39%' }}
              multiline
              maxRows={10}
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              label="Title"
            />
            <TextField
              style={{ width: '39%', marginLeft: 10 }}
              multiline
              maxRows={10}
              name="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              label="Description"
            />
            <LoadingButton
              onClick={() => handleClick()}
              style={{ width: 220, height: 55, marginTop: 0, marginLeft: 20 }}
              fullWidth
              size="large"
              variant="contained"
            >
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </Card>

      <div style={{ marginTop: 20 }}>
        <Card>
          {/* <div style={{ marginTop: 20, marginBottom: 20, marginLeft: 20 }}>
                        <StyledSearch
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            placeholder="Search..."
                            startAdornment={
                                <InputAdornment position="start">
                                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                                </InputAdornment>
                            }
                        />
                    </div> */}

          <Scrollbar>
            <TableContainer sx={{ minWidth: 720 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ minWidth: 240 }}>Image</TableCell>
                    <TableCell sx={{ minWidth: 160 }}>Title</TableCell>
                    <TableCell sx={{ minWidth: 160 }}>Description</TableCell>
                    <TableCell sx={{ minWidth: 160 }}>Action</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {search(TABLE_DATA)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { id, image, title, description } = row;

                      return (
                        <TableRow key={id}>
                          <TableCell>
                            <Avatar
                              style={{ borderRadius: 0, width: 200, height: 200 }}
                              alt={image}
                              src={BaseURLImage + image}
                            />
                          </TableCell>
                          <TableCell>{title}</TableCell>
                          <TableCell>{description}</TableCell>
                          <TableCell>
                            <LoadingButton
                              onClick={() =>
                                confirmAlert({
                                  title: 'Confirm to submit',
                                  message: 'Are you sure to delete this content?',
                                  buttons: [
                                    {
                                      label: 'Yes',
                                      onClick: () => handleDelete(id),
                                    },
                                    {
                                      label: 'No',
                                      onClick: () => console.log('NO'),
                                    },
                                  ],
                                })
                              }
                              style={{ width: 100, height: 30, backgroundColor: 'red' }}
                              fullWidth
                              size="large"
                              variant="contained"
                            >
                              Delete
                            </LoadingButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{TABLE_DATA}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={search(TABLE_DATA).length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </div>
    </>
  );
}
