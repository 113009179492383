import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid, Card, Stack, Typography, TextField, OutlinedInput } from '@mui/material';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import axios from 'axios';
import { Oval } from 'react-loader-spinner';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';

import { BaseURL } from '../common/Base_Url';

export default function ChangePassword() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    GetUserProfile();
  }, []);

  const GetUserProfile = () => {
    setLoading(true);

    const TOKEN = localStorage.getItem('currentUserAccessToken');

    fetch(`${BaseURL}users/17`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setLoading(false);
        setEmail(responseJson.profile[0].email);
      })
      .catch((error) => {
        setLoading(false);
        alert(JSON.stringify(error));
      });
  };

  const handleClick = () => {
    if (!email) {
      alert('Please enter your email');
    } else if (!password) {
      alert('Please enter your password');
    } else {
      setLoading(true);

      const TOKEN = localStorage.getItem('currentUserAccessToken');

      fetch(`${BaseURL}updateAdminProfile`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
          Authorization: `Bearer ${TOKEN}`,
        },
        body: JSON.stringify({
          id: '17',
          email,
          password,
        }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          setLoading(false);
          alert('Profile Update Successfully');
          localStorage.removeItem('currentUserAccessToken');
          window.location.href = '/';
        })
        .catch((error) => {
          setLoading(false);
          alert(JSON.stringify(error));
        });
    }
  };

  return (
    <>
      <Helmet>
        <title> Change Password | Nova Auto Spa </title>
      </Helmet>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Change Email & Password
        </Typography>
        {loading === true ? (
          <Oval
            height={30}
            width={30}
            color="#0F70F5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={'true'}
            ariaLabel="oval-loading"
            secondaryColor="#2e3a59"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        ) : null}
      </Stack>

      <Card style={{ padding: 20 }}>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6} md={6}>
            <TextField
              style={{ width: '100%', marginTop: 20 }}
              multiline
              maxRows={10}
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label="Email"
            />
            <TextField
              style={{ width: '100%', marginTop: 20 }}
              multiline
              maxRows={10}
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              label="Password"
            />
            <LoadingButton
              onClick={() => handleClick()}
              style={{ width: 220, height: 55, marginTop: 20 }}
              fullWidth
              size="large"
              variant="contained"
            >
              Change
            </LoadingButton>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
