import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { sentenceCase } from 'change-case';
// @mui
import {
  Grid,
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  TableRow,
  MenuItem,
  TableBody,
  TableHead,
  TableCell,
  Container,
  Typography,
  TextField,
  TableContainer,
  TablePagination,
  OutlinedInput,
  InputAdornment,
  Switch,
  Modal,
  Button,
  Box,
} from '@mui/material';
import { confirmAlert } from 'react-confirm-alert'; // Import
import moment from 'moment';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import axios from 'axios';
import { Oval } from 'react-loader-spinner';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { BaseURL, BaseURLImage } from '../common/Base_Url';

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

export default function BookingAvailability() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [selectedPackage, setSelectedPackage] = useState([]);
  const [selectedSlotDate, setSelectedSlotDate] = useState('');
  const [selectedSlotTime, setSelectedSlotTime] = useState('');
  const [selectedSpots, setSelectedSpots] = useState('');
  const [selectedRowId, setSelectedRowId] = useState('');
  const [selectedUpdateSlotTime, setSelectedUpdateSlotTime] = useState('');
  const [selectedUpdateSpots, setSelectedUpdateSpots] = useState('');
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
  const [time, setTime] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState([]);
  const [categoryName, setCategoryName] = useState('');
  const [categoryImage, setCategoryImage] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [query, setQuery] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const [allpackages, setAllPackages] = useState([]);
  const [TABLE_DATA, setAllPackagesItems] = useState([]);

  useEffect(() => {
    getAllSlots();
  }, []);

  const getAllSlots = (date) => {
    setSelectedDate(date);
    const DATE = date ? moment(date).format('YYYY-MM-DD') : moment(selectedDate).format('YYYY-MM-DD');
    setLoading(true);

    const TOKEN = localStorage.getItem('currentUserAccessToken');
    const BODY = {};

    fetch(`${BaseURL}getBookingSlots`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: `Bearer ${TOKEN}`,
      },
      body: JSON.stringify({
        date: DATE,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setLoading(false);
        setAllPackagesItems(responseJson.message);
      })
      .catch((error) => {
        setLoading(false);
        alert(JSON.stringify(error));
      });
  };

  const handleClick = () => {
    if (selectedSlotDate === '') {
      alert('Please Select Spot Date');
    } else if (selectedSlotTime === '') {
      alert('Please Select Spot Time');
    } else if (selectedSpots === '') {
      alert('Please Enter Spots');
    } else {
      setLoading(true);

      const TOKEN = localStorage.getItem('currentUserAccessToken');

      fetch(`${BaseURL}createBookingSlot`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
          Authorization: `Bearer ${TOKEN}`,
        },
        body: JSON.stringify({
          date: selectedSlotDate,
          hour: selectedSlotTime,
          slot: selectedSpots,
        }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          setLoading(false);

          if (responseJson.status === 200) {
            getAllSlots();
          } else if (responseJson.message === 'Slot already available') {
            alert('Spot already available');
          } else {
            alert('Something Went Wrong Please Try Again');
            getAllSlots();
          }
        })
        .catch((error) => {
          alert(JSON.stringify(error));
        });
    }
  };

  const handleUpdateRecord = () => {
    if (selectedUpdateSlotTime === '') {
      alert('Please Select Spot Time');
    } else if (selectedUpdateSpots === '') {
      alert('Please Enter Spots');
    } else {
      setLoading(true);

      const TOKEN = localStorage.getItem('currentUserAccessToken');

      fetch(`${BaseURL}updateBookingSlot`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
          Authorization: `Bearer ${TOKEN}`,
        },
        body: JSON.stringify({
          id: selectedRowId,
          hour: selectedUpdateSlotTime,
          slot: selectedUpdateSpots,
        }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          setLoading(false);

          if (responseJson.status === 200) {
            setModalOpen(false);
            getAllSlots();
          } else if (responseJson.message === 'Slot already available') {
            setModalOpen(false);
            alert('Spot already available');
          } else {
            setModalOpen(false);

            alert('Something Went Wrong Please Try Again');
            getAllSlots();
          }
        })
        .catch((error) => {
          alert(JSON.stringify(error));
        });
    }
  };

  const handleSwitchChange = (id, currentStatus) => {
    setLoading(true);

    const TOKEN = localStorage.getItem('currentUserAccessToken');

    const newStatus = currentStatus === 1 ? 0 : 1;

    fetch(`${BaseURL}statusBookingSlotChange`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: `Bearer ${TOKEN}`,
      },
      body: JSON.stringify({
        id,
        status: newStatus,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setLoading(false);

        if (responseJson.status === 200) {
          getAllSlots();
        } else {
          alert('Something Went Wrong Please Try Again');
          getAllSlots();
        }
      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });
  };

  const handleDelete = (ID) => {
    setLoading(true);

    const TOKEN = localStorage.getItem('currentUserAccessToken');

    const BODY = {
      id: ID,
    };

    fetch(`${BaseURL}deleteBookingSlot`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: `Bearer ${TOKEN}`,
      },
      body: JSON.stringify(BODY),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setLoading(false);

        if (responseJson.status === 200) {
          getAllSlots();
        } else {
          alert('Something Went Wrong Please Try Again');
          getAllSlots();
        }
      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const search = (data) => {
    return data.filter((item) => item.date.toLowerCase().includes(query));
  };

  const handleDateChange = (e) => {
    getAllSlots(e.target.value);
  };

  const handleModalOpen = (id, hour, spots) => {
    setSelectedRowId(id);
    setSelectedUpdateSlotTime(hour);
    setSelectedUpdateSpots(spots);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - search(TABLE_DATA).length) : 0;
  const isNotFound = !TABLE_DATA.length && !!TABLE_DATA;

  return (
    <>
      <Helmet>
        <title> Booking Availability | AutoNova Auto SpaSpa </title>
      </Helmet>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Booking Availability
        </Typography>
        {loading === true ? (
          <Oval
            height={30}
            width={30}
            color="#0F70F5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={'true'}
            ariaLabel="oval-loading"
            secondaryColor="#2e3a59"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        ) : null}
      </Stack>

      <Card style={{ padding: 20 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              id="slot-date"
              label="Slot Date"
              type="date"
              value={selectedSlotDate}
              onChange={(e) => setSelectedSlotDate(e.target.value)}
              sx={{ mb: 2 }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: moment().format('YYYY-MM-DD'),
              }}
            />
            <TextField
              id="slot-time"
              label="Slot Time"
              type="time"
              value={selectedSlotTime}
              onChange={(e) => setSelectedSlotTime(e.target.value)}
              sx={{ mb: 2, ml: 2 }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            />
            <TextField
              style={{ marginLeft: 20, marginRight: 20 }}
              type="number"
              name="slots"
              label="Spots"
              value={selectedSpots}
              onChange={(e) => setSelectedSpots(e.target.value)}
            />
            <LoadingButton
              onClick={() => handleClick()}
              style={{ width: 150, height: 55, marginTop: 0 }}
              fullWidth
              size="large"
              variant="contained"
            >
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </Card>

      <div style={{ marginTop: 20 }}>
        <Card>
          <div style={{ marginTop: 20, marginBottom: 20, marginLeft: 20 }}>
            <TextField
              id="booking-date"
              label="Search Date"
              type="date"
              value={selectedDate}
              onChange={handleDateChange}
              sx={{ mb: 2 }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: moment().format('YYYY-MM-DD'),
              }}
            />
          </div>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 720 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ minWidth: 160 }}>Service Hours</TableCell>
                    <TableCell sx={{ minWidth: 160 }}>Available Spots</TableCell>
                    <TableCell sx={{ minWidth: 160 }}>Edit</TableCell>
                    <TableCell sx={{ minWidth: 160 }}>Delete</TableCell>
                    <TableCell sx={{ minWidth: 160 }}>Enable / Disable</TableCell>
                    <TableCell sx={{ minWidth: 160 }}>Status</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {search(TABLE_DATA)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { id, date, hour, slots, status } = row;

                      return (
                        <TableRow key={id}>
                          <TableCell>{moment(hour, 'HH:mm').format('hh:mm A')}</TableCell>
                          <TableCell>{slots}</TableCell>
                          <TableCell>
                            <LoadingButton
                              onClick={() => handleModalOpen(id, hour, slots)}
                              style={{ width: 100, height: 30 }}
                              fullWidth
                              size="large"
                              variant="contained"
                            >
                              Edit
                            </LoadingButton>
                          </TableCell>
                          <TableCell>
                            <LoadingButton
                              onClick={() =>
                                confirmAlert({
                                  title: 'Confirm to submit',
                                  message: 'Are you sure to delete this spot?',
                                  buttons: [
                                    {
                                      label: 'Yes',
                                      onClick: () => handleDelete(id),
                                    },
                                    {
                                      label: 'No',
                                      onClick: () => console.log('NO'),
                                    },
                                  ],
                                })
                              }
                              style={{ width: 100, height: 30, backgroundColor: 'red' }}
                              fullWidth
                              size="large"
                              variant="contained"
                            >
                              Delete
                            </LoadingButton>
                          </TableCell>
                          <TableCell>
                            <Switch checked={status === 1} onChange={() => handleSwitchChange(id, status)} />
                          </TableCell>
                          <TableCell align="left">
                            <Label color={(status === 1 && 'error') || 'success'}>
                              {sentenceCase(status === 0 ? 'Active' : 'Block')}
                            </Label>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{TABLE_DATA}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={search(TABLE_DATA).length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        <Modal
          open={modalOpen}
          onClose={handleModalClose}
          aria-labelledby="booking-modal-title"
          aria-describedby="booking-modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              maxWidth: 400,
              minWidth: 300,
            }}
          >
            <Typography variant="h6" style={{ marginBottom: 30 }} id="booking-modal-title" gutterBottom>
              Update Record
            </Typography>
            <TextField
              id="slot-time"
              label="Slot Time"
              type="time"
              value={selectedUpdateSlotTime}
              onChange={(e) => setSelectedUpdateSlotTime(e.target.value)}
              sx={{ mb: 2 }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            />
            <TextField
              style={{ marginBottom: 20 }}
              type="number"
              name="slots"
              label="Spots"
              value={selectedUpdateSpots}
              onChange={(e) => setSelectedUpdateSpots(e.target.value)}
            />
            <Button variant="contained" onClick={handleUpdateRecord}>
              Update Record
            </Button>
          </Box>
        </Modal>
      </div>
    </>
  );
}
