import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { sentenceCase } from 'change-case';
import moment from 'moment';
// @mui
import {
  Avatar,
  Grid,
  Card,
  Table,
  Stack,
  Paper,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  OutlinedInput,
  InputAdornment,
  MenuItem,
  TextField,
  Modal,
  Button,
  Box,
} from '@mui/material';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { Oval } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import { RequestPushMsg } from '../RequestPushMsg';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { BaseURL, BaseURLImage } from '../common/Base_Url';
import DashboradTablePopUpMenu from './dashboardTablePopUpMenu';

import { AppWidgetSummary } from '../sections/@dashboard/app';

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

const SORT_OPTIONS = [
  { value: 'DESC', label: 'Soonest ' },
  { value: 'ASC', label: 'Closest ' },
];

const SORT_OPTIONS_STATUS = [
  { value: 'confirm', label: 'Completed' },
  { value: 'pending', label: 'Pending' },
  { value: 'cancel', label: 'Cancelled ' },
];

export default function DashboardAppPage() {
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [selectedBookingUserID, setSelectedBookingUserID] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');

  const [completeBookings, setCompleteBookings] = useState(0);
  const [pendingBookings, setPendingBookings] = useState(0);
  const [inProcessBookings, setInProcessBookings] = useState(0);
  const [cancelBookings, setCancelBookings] = useState(0);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [query, setQuery] = useState('');
  const [selectSortBy, setSelectSortBy] = useState('DESC');
  const [selectSortByStatus, setSelectSortByStatus] = useState('None');

  const [TABLE_DATA, setAllBookings] = useState([]);

  useEffect(() => {
    getAlBookings('DESC');
  }, []);

  const getAlBookings = (sort, status) => {
    setLoading(true);

    const TOKEN = localStorage.getItem('currentUserAccessToken');

    let data;

    if (status) {
      data = {
        sortby: sort,
        status,
      };
    } else {
      data = {
        sortby: sort,
      };
    }

    fetch(`${BaseURL}getAllBookings`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: `Bearer ${TOKEN}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const PENDING_BOOKINGS = responseJson.bookings.filter((item) => item.status === 'pending');
        const CONFIRM_BOOKINGS = responseJson.bookings.filter((item) => item.status === 'confirm');
        const CANCEL_BOOKINGS = responseJson.bookings.filter((item) => item.status === 'cancel');

        setCompleteBookings(CONFIRM_BOOKINGS.length ? CONFIRM_BOOKINGS.length : 0);
        setPendingBookings(PENDING_BOOKINGS.length ? PENDING_BOOKINGS.length : 0);
        setCancelBookings(CANCEL_BOOKINGS.length ? CANCEL_BOOKINGS.length : 0);

        const DATA = [];
        responseJson.bookings.forEach((element) => {
          DATA.push({
            id: element.id,
            bookingID: element.id.toString(),
            userId: element.user_id,
            firstName: element.firstName,
            lastName: element.lastName,
            phoneNumber: element.phoneNumber,
            bookingDate: element.bookingDate,
            bookingTime: element.bookingTime,
            residentialArea: element.residentialArea,
            locationDetails: element.locationDetails,
            bookingPrice: element.bookingPrice,
            bookingAddress: element.bookingAddress,
            vehicleId: element.vehicleId,
            vehicleYear: element.vehicleYear,
            vehicleMake: element.vehicleMake,
            vehicleType: element.vehicleType,
            vehicleModel: element.vehicleModel,
            vehicleImage: element.vehicleImage,
            packageID: element.packageID,
            packageName: element.packageName,
            packageTime: element.packageTime,
            packageItems: element.packageItems,
            status: element.status,
            paymentMethod: element.paymentMethod,
            createdAt: element.created_at,
          });
        });

        setLoading(false);

        setAllBookings(DATA);
      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });
  };

  const changeBookingStatus = (id, status, userId) => {
    setLoading(true);

    const TOKEN = localStorage.getItem('currentUserAccessToken');

    fetch(`${BaseURL}updateBookingStatus`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: `Bearer ${TOKEN}`,
      },
      body: JSON.stringify({
        id,
        status,
        userId,
        fromAdminPending: 'true',
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let message;

        if (status === 'pending') {
          message =
            'El estatus de tu reservación se encuentra pendiente. Para conocer más detalles, contáctanos por chat.';
        } else if (status === 'cancel') {
          message = 'Tu servicio ha sido cancelado. Para conocer más detalles, contáctanos por chat.';
        } else if (status === 'confirm') {
          message =
            '¡Tu servicio ha sido confirmado exitosamente! Si tu domicilio requiere código de acceso, favor de enviarlo por chat.';
        }
        if (status !== 'pending') {
          storeNotification(userId, `(Alertas) Nova Auto Spa`, message);
        } else {
          setLoading(false);
          getAlBookings('DESC');
        }
      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });
  };

  const storeNotification = (USER_ID, title, description) => {
    setLoading(true);

    const TOKEN = localStorage.getItem('currentUserAccessToken');

    fetch(`${BaseURL}createNotification`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: `Bearer ${TOKEN}`,
      },
      body: JSON.stringify({
        user_id: USER_ID,
        title,
        description,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setLoading(false);
        getAlBookings('DESC');
      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });
  };

  const updateBooking = (id, bookingDate, bookingTime, userId) => {
    setLoading(true);

    const TOKEN = localStorage.getItem('currentUserAccessToken');

    fetch(`${BaseURL}updateBooking`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: `Bearer ${TOKEN}`,
      },
      body: JSON.stringify({
        id,
        bookingDate,
        bookingTime,
        userId,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setModalOpen(false);

        setSelectedDate('');
        setSelectedTime('');

        const message =
          '¡Tu servicio ha sido reagendado exitosamente! Para conocer más detalles, contáctanos por chat. Si tu domicilio requiere código de acceso, favor de enviarlo.';

        storeNotification(userId, `(Alertas) Nova Auto Spa`, message);
      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const search = (data) => {
    return data.filter((item) => item.bookingID.toLowerCase().includes(query));
  };

  const clickSort = (e) => {
    setSelectSortBy(e);
    getAlBookings(e);
  };

  const clickSortStatus = (e) => {
    setSelectSortByStatus(e);
    if (e !== 'None') {
      getAlBookings(selectSortBy, e);
    } else {
      getAlBookings(selectSortBy, undefined);
    }
  };

  const handleModalOpen = (booking, userId) => {
    setSelectedBookingUserID(userId);
    setSelectedBooking(booking);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleTimeChange = (e) => {
    setSelectedTime(e.target.value);
  };

  const handleSaveChanges = () => {
    if (!selectedDate || !selectedTime) {
      alert('Please select date and time');
    } else {
      const formattedTime = moment(selectedTime, 'HH:mm').format('h:mm A');

      updateBooking(selectedBooking.id, selectedDate, formattedTime, selectedBookingUserID);
    }
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - search(TABLE_DATA).length) : 0;
  const isNotFound = !TABLE_DATA.length && !!TABLE_DATA;

  return (
    <>
      <Helmet>
        <title> Bookings | Nova Auto Spa </title>
      </Helmet>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Bookings
        </Typography>
        {loading === true ? (
          <Oval
            height={30}
            width={30}
            color="#0F70F5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={'true'}
            ariaLabel="oval-loading"
            secondaryColor="#2e3a59"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        ) : null}
      </Stack>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <AppWidgetSummary
            title="Pending Bookings"
            total={pendingBookings}
            color="info"
            icon={'ant-design:calendar'}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <AppWidgetSummary
            title="Completed Bookings"
            total={completeBookings}
            color="info"
            icon={'ant-design:calendar'}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <AppWidgetSummary
            title="Cancelled Bookings"
            total={cancelBookings}
            color="info"
            icon={'ant-design:calendar'}
          />
        </Grid>
      </Grid>

      <div style={{ marginTop: 20 }}>
        <Card>
          <div style={{ marginTop: 20, marginBottom: 20, marginLeft: 20 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <StyledSearch
                  style={{ height: 45 }}
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder="Search By Booking ID"
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item xs={12} sm={1.5}>
                <TextField
                  select
                  label="Filter By"
                  size="small"
                  value={selectSortByStatus}
                  onChange={(e) => clickSortStatus(e.target.value)}
                >
                  <MenuItem key={'None'} value={'None'}>
                    {'None'}
                  </MenuItem>
                  {SORT_OPTIONS_STATUS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  select
                  label="Sort By"
                  size="small"
                  value={selectSortBy}
                  onChange={(e) => clickSort(e.target.value)}
                >
                  {SORT_OPTIONS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </div>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 720 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ border: 1, minWidth: 130, fontWeight: 'bold', color: '#000000' }}>
                      Booking Date
                    </TableCell>
                    <TableCell sx={{ border: 1, minWidth: 130, fontWeight: 'bold', color: '#000000' }}>
                      Booking Time
                    </TableCell>
                    <TableCell sx={{ border: 1, minWidth: 120, fontWeight: 'bold', color: '#000000' }}>
                      Client’s Name
                    </TableCell>
                    <TableCell sx={{ border: 1, minWidth: 140, fontWeight: 'bold', color: '#000000' }}>
                      Phone Number
                    </TableCell>
                    <TableCell sx={{ border: 1, minWidth: 150, fontWeight: 'bold', color: '#000000' }}>
                      Residential Area
                    </TableCell>
                    <TableCell sx={{ border: 1, minWidth: 170, fontWeight: 'bold', color: '#000000' }}>
                      Booking Address
                    </TableCell>
                    <TableCell sx={{ border: 1, minWidth: 170, fontWeight: 'bold', color: '#000000' }}>
                      Location Details
                    </TableCell>
                    <TableCell sx={{ border: 1, minWidth: 120, fontWeight: 'bold', color: '#000000' }}>
                      Vehicle Information
                    </TableCell>
                    <TableCell sx={{ border: 1, minWidth: 120, fontWeight: 'bold', color: '#000000' }}>
                      Vehicle Type
                    </TableCell>
                    <TableCell sx={{ border: 1, minWidth: 135, fontWeight: 'bold', color: '#000000' }}>
                      Servicio
                    </TableCell>
                    <TableCell sx={{ border: 1, minWidth: 135, fontWeight: 'bold', color: '#000000' }}>
                      Extras
                    </TableCell>
                    <TableCell sx={{ border: 1, minWidth: 135, fontWeight: 'bold', color: '#000000' }}>
                      Booking Price
                    </TableCell>
                    <TableCell sx={{ border: 1, minWidth: 135, fontWeight: 'bold', color: '#000000' }}>
                      Payment Method
                    </TableCell>
                    <TableCell sx={{ border: 1, minWidth: 120, fontWeight: 'bold', color: '#000000' }}>
                      Booking ID
                    </TableCell>
                    <TableCell sx={{ border: 1, minWidth: 135, fontWeight: 'bold', color: '#000000' }}>
                      Date Created
                    </TableCell>
                    <TableCell sx={{ border: 1, minWidth: 120, fontWeight: 'bold', color: '#000000' }}>
                      Status
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {search(TABLE_DATA)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        id,
                        bookingID,
                        userId,
                        firstName,
                        lastName,
                        phoneNumber,
                        bookingDate,
                        bookingTime,
                        residentialArea,
                        bookingPrice,
                        bookingAddress,
                        locationDetails,
                        vehicleId,
                        vehicleMake,
                        vehicleYear,
                        vehicleType,
                        vehicleModel,
                        packageID,
                        packageName,
                        packageTime,
                        packageItems,
                        status,
                        paymentMethod,
                        createdAt,
                      } = row;

                      return (
                        <TableRow
                          key={id}
                          sx={{
                            backgroundColor:
                              status === 'confirm' ? '#F0FAEC' : status === 'pending' ? '#CBE2F2' : '#FFCCCC',
                          }}
                        >
                          <TableCell sx={{ border: 1 }}>{moment(bookingDate).format('LL')}</TableCell>
                          <TableCell sx={{ border: 1 }}>{moment(bookingTime, 'HH:mm').format('hh:mm A')}</TableCell>
                          <TableCell sx={{ border: 1 }}>
                            {firstName} {lastName}
                          </TableCell>
                          <TableCell sx={{ border: 1 }}>{phoneNumber}</TableCell>
                          <TableCell sx={{ border: 1 }}>{residentialArea}</TableCell>
                          <TableCell sx={{ border: 1 }}>{bookingAddress}</TableCell>
                          <TableCell sx={{ border: 1 }}>{locationDetails}</TableCell>
                          <TableCell sx={{ border: 1 }}>
                            {vehicleYear} {vehicleMake} {vehicleModel}
                          </TableCell>
                          <TableCell sx={{ border: 1 }}>{vehicleType}</TableCell>
                          <TableCell sx={{ border: 1 }}>{packageName}</TableCell>
                          <TableCell sx={{ border: 1 }}>{packageItems}</TableCell>
                          <TableCell sx={{ border: 1 }}>${parseInt(bookingPrice, 10).toFixed(2)}</TableCell>
                          <TableCell sx={{ border: 1 }}>{paymentMethod}</TableCell>
                          <TableCell sx={{ border: 1 }}>{id}</TableCell>
                          <TableCell sx={{ border: 1 }}>{moment(createdAt).format('LLL')}</TableCell>
                          <TableCell sx={{ border: 1 }} align="left">
                            <Label
                              color={
                                status === 'cancel'
                                  ? 'error'
                                  : status === 'pending'
                                  ? 'secondary'
                                  : status === 'confirm'
                                  ? 'success'
                                  : status === 'confirm'
                                  ? 'default'
                                  : ''
                              }
                            >
                              {sentenceCase(status)}
                            </Label>
                          </TableCell>
                          <TableCell align="right">
                            <DashboradTablePopUpMenu
                              onEditPress={() => handleModalOpen(row, userId)}
                              onPendingPress={() => changeBookingStatus(id, 'pending', userId)}
                              onCancelPress={() =>
                                confirmAlert({
                                  title: 'Confirm to submit',
                                  message: 'Are you sure you want to cancel this booking?',
                                  buttons: [
                                    {
                                      label: 'Yes',
                                      onClick: () => changeBookingStatus(id, 'cancel', userId),
                                    },
                                    {
                                      label: 'No',
                                      onClick: () => console.log('NO'),
                                    },
                                  ],
                                })
                              }
                              onConfirmPress={() =>
                                confirmAlert({
                                  title: 'Confirm to submit',
                                  message: 'Are you sure you want to confirm this booking?',
                                  buttons: [
                                    {
                                      label: 'Yes',
                                      onClick: () => changeBookingStatus(id, 'confirm', userId),
                                    },
                                    {
                                      label: 'No',
                                      onClick: () => console.log('NO'),
                                    },
                                  ],
                                })
                              }
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{TABLE_DATA}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={search(TABLE_DATA).length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        <Modal
          open={modalOpen}
          onClose={handleModalClose}
          aria-labelledby="booking-modal-title"
          aria-describedby="booking-modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              maxWidth: 400,
              minWidth: 300,
            }}
          >
            <Typography variant="h6" style={{ marginBottom: 30 }} id="booking-modal-title" gutterBottom>
              Change Booking Date & Time
            </Typography>
            <TextField
              id="booking-date"
              label="Booking Date"
              type="date"
              value={selectedDate}
              onChange={handleDateChange}
              sx={{ mb: 2 }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: moment().format('YYYY-MM-DD'),
              }}
            />
            <TextField
              id="booking-time"
              label="Booking Time"
              type="time"
              value={selectedTime}
              onChange={handleTimeChange}
              sx={{ mb: 2 }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            />
            <Button variant="contained" onClick={handleSaveChanges}>
              Save Changes
            </Button>
          </Box>
        </Modal>
      </div>
    </>
  );
}
