// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  // {
  //   title: 'bookings',
  //   path: '/dashboard/bookings',
  //   icon: icon('ic_booking'),
  // },
  {
    title: 'users',
    path: '/dashboard/user',
    icon: icon('ic_user'),
  },
  {
    title: 'packages',
    path: '/dashboard/packages',
    icon: icon('ic_kanban'),
  },
  {
    title: 'Optional Services',
    path: '/dashboard/packagesItems',
    icon: icon('ic_kanban'),
  },
  {
    title: 'Booking Availability',
    path: '/dashboard/bookingAvailability',
    icon: icon('ic_kanban'),
  },
  {
    title: 'support',
    path: '/dashboard/support',
    icon: icon('ic_kanban'),
  },
  {
    title: 'Chat',
    path: '/dashboard/chatinbox',
    icon: icon('ic_kanban'),
  },
  {
    title: 'About',
    path: '/dashboard/about',
    icon: icon('ic_kanban'),
  },
  {
    title: 'Promotions',
    path: '/dashboard/promotionspage',
    icon: icon('ic_kanban'),
  },
  {
    title: 'Memberships',
    path: '/dashboard/memberships',
    icon: icon('ic_kanban'),
  },
  {
    title: 'FAQ',
    path: '/dashboard/faq',
    icon: icon('ic_kanban'),
  },
  {
    title: 'Residential Areas',
    path: '/dashboard/residentialareas',
    icon: icon('ic_kanban'),
  },
  {
    title: 'Discount codes',
    path: '/dashboard/discountcoupons',
    icon: icon('ic_kanban'),
  },
  {
    title: 'Work With Us',
    path: '/dashboard/workwithus',
    icon: icon('ic_kanban'),
  },
  {
    title: 'Change Password',
    path: '/dashboard/changepassword',
    icon: icon('ic_kanban'),
  },
  {
    title: 'Notifications',
    path: '/dashboard/notifications',
    icon: icon('ic_kanban'),
  },
];

export default navConfig;
