import React, { useContext } from 'react'
import Messages from './Messages'
import Input from './Input'
import { ChatContext } from '../../context/ChatContext'

const Chat = () => {
    const { data } = useContext(ChatContext)
    return (
        <div style={{ flex: 2 }}>
            <div className='chatInfo'>
                {Object.keys(data.user).length === 0 ? null :
                    <img className='userChatImage' style={{ marginRight: 10 }} alt='' src='https://img.freepik.com/free-icon/user_318-159711.jpg' />
                }
                <div className='userChatInfo' style={{ marginTop: 10 }}>
                    {Object.keys(data.user).length === 0 ? null :
                        <span>{data.user?.participants[0].name}</span>
                    }
                </div>
            </div>
            <Messages />
            <Input />
        </div>
    )
}

export default Chat
