import { Helmet } from 'react-helmet-async';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableHead,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import { Oval } from 'react-loader-spinner';
// components
import { styled, alpha } from '@mui/material/styles';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import UserTablePopUpMenu from './Users/userTablePopUpMenu';
import { BaseURL, BaseURLImage } from '../common/Base_Url';
import { db } from '../firebase';

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

export default function UserPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [query, setQuery] = useState('');
  const [TABLE_DATA, setAllUsers] = useState([]);
  const [UserID, serUserID] = useState('');

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = () => {
    setLoading(true);

    const TOKEN = localStorage.getItem('currentUserAccessToken');

    const BODY = {};

    fetch(`${BaseURL}getAllUsers`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: `Bearer ${TOKEN}`,
      },
      body: JSON.stringify(BODY),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setLoading(false);
        setAllUsers(responseJson.users);
        console.log(responseJson.users);
      })
      .catch((error) => {
        setLoading(false);
        alert(JSON.stringify(error));
      });
  };

  const setUserActiveState = (USER_ID, STATE) => {
    setLoading(true);

    const TOKEN = localStorage.getItem('currentUserAccessToken');
    const BODY = {
      id: USER_ID,
      isBlock: STATE === 1 ? 0 : 1,
    };

    fetch(`${BaseURL}updateUserActiveByUserID`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: `Bearer ${TOKEN}`,
      },
      body: JSON.stringify(BODY),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setLoading(false);

        if (responseJson.status === 200) {
          getAllUsers();
        } else {
          alert(JSON.stringify(responseJson));
        }
      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });
  };

  const setUserVerifiedState = (USER_ID, STATE) => {
    setLoading(true);

    const TOKEN = localStorage.getItem('currentUserAccessToken');
    const BODY = {
      id: USER_ID,
      verified: STATE === 1 ? 0 : 1,
    };

    fetch(`${BaseURL}updateUserVerifiedByUserID`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: `Bearer ${TOKEN}`,
      },
      body: JSON.stringify(BODY),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setLoading(false);

        if (responseJson.status === 200) {
          getAllUsers();
        } else {
          alert(JSON.stringify(responseJson));
        }
      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });
  };

  const checkUserExistOrNot = async (UID) => {
    setLoading(true);

    const checkUserExist = await db.collection('users').doc(`${UID}`).get();

    if (checkUserExist.exists === false) {
      db.collection('users')
        .doc(`${UID}`)
        .set({
          id: `${UID}`,
          chat_rooms: [],
        })
        .then((response) => {
          setLoading(false);
          currentUser(UID);
        });
    } else {
      currentUser(UID);
    }
  };

  const currentUser = async (USER_ID) => {
    setLoading(true);

    const TOKEN = localStorage.getItem('currentUserAccessToken');

    fetch(`${BaseURL}users/${USER_ID}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson) {
          serUserID(responseJson.profile[0].id);
          setTimeout(() => {
            const id = {
              id: 17,
              name: `NOVA Auto Spa`,
              image: null,
            };
            CheckUserandCreateRoom(responseJson.profile[0], id);
          }, 1500);
        }
      })
      .catch((error) => {
        setLoading(false);
        alert(JSON.stringify(error));
      });
  };

  const CheckUserandCreateRoom = async (Userdata, id) => {
    setLoading(true);

    const checkUserExist = await db.collection('users').doc(`${Userdata.id}`).get();

    if (checkUserExist && checkUserExist.exists) {
      const querySnap1 = await db.collection('users').where('id', '==', `${Userdata.id}`).get();

      const querySnap2 = await db.collection('users').where('id', '==', `${id.id}`).get();

      const key = querySnap1.docs.map((docSnap) => {
        const data = docSnap.data();
        return data.chat_rooms;
      });

      const key2 = querySnap2.docs.map((docSnap) => {
        const data = docSnap.data();
        return data.chat_rooms;
      });

      const intersection = key[0].filter((x) => key2[0].includes(x));

      const filter = intersection.map((docSnap) => {
        if (docSnap.charAt(0) !== 'g') {
          return docSnap;
        }
        return null;
      });

      const toString = filter.toString();
      const finalName = toString.replace(/,/g, '');

      if (finalName === '') {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;

        for (let i = 0; i < 10; i += 1) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        db.collection(`chat_rooms`).add({
          id: result,
          isGroupChat: false,
          last_message: '',
          sender_id: Userdata.id,
          participants: [
            {
              id: Userdata.id,
              name: `${Userdata.firstName} ${Userdata.lastName}`,
              image: Userdata.profileImage,
            },
            {
              id: id.id,
              name: `${id.name}`,
              image: id.image,
            },
          ],
          groupName: '',
          groupIcon: '',
          updated_at: new Date(),
        });

        addRoomIdToPartChatRoom(Userdata, result, id);
      } else {
        setLoading(false);
        navigate('/dashboard/chatinbox', { replace: true });
      }
    } else {
      setLoading(false);
      alert('User not available!');
    }
  };

  const addRoomIdToPartChatRoom = async (Userdata, RoomID, id) => {
    const checkrooms = await db.collection('users').where('id', '==', `${Userdata.id}`).get();

    const rooms = checkrooms.docs.map((docSnap) => {
      const data = docSnap.data();
      return data;
    });

    db.collection('users')
      .doc(`${Userdata.id}`)
      .update({
        chat_rooms: [...rooms[0].chat_rooms, RoomID],
      });

    const checkrooms2 = await db.collection('users').where('id', '==', `${id.id}`).get();

    const rooms2 = checkrooms2.docs.map((docSnap) => {
      const data = docSnap.data();
      return data;
    });

    db.collection(`users`)
      .doc(`${id.id}`)
      .update({
        chat_rooms: [...rooms2[0].chat_rooms, RoomID],
      });

    setLoading(false);

    navigate('/dashboard/chatinbox', { replace: true });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const search = (data) => {
    return data.filter(
      (item) =>
        item.firstName.toLowerCase().includes(query) ||
        item.email.toLowerCase().includes(query) ||
        item.phone.toLowerCase().includes(query)
    );
  };

  const handleDeleteUser = (userId) => {
    // const deleteUser = TABLE_DATA.filter((user) => user.id !== userId);
    // setTabledata(deleteUser);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - search(TABLE_DATA).length) : 0;
  const isNotFound = !TABLE_DATA.length && !!TABLE_DATA;

  return (
    <>
      <Helmet>
        <title> Users | Nova Auto Spa </title>
      </Helmet>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Users
        </Typography>
        {loading === true ? (
          <Oval
            height={30}
            width={30}
            color="#0F70F5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={'true'}
            ariaLabel="oval-loading"
            secondaryColor="#2e3a59"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        ) : null}
      </Stack>

      <div>
        <Card>
          <div style={{ marginTop: 20, marginBottom: 20, marginLeft: 20 }}>
            <StyledSearch
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Search user..."
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
            />
          </div>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 720 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ minWidth: 160 }}>Image</TableCell>
                    <TableCell sx={{ minWidth: 160 }}>First Name</TableCell>
                    <TableCell sx={{ minWidth: 160 }}>Last Name</TableCell>
                    <TableCell sx={{ minWidth: 160 }}>Email</TableCell>
                    <TableCell sx={{ minWidth: 120 }}>Phone</TableCell>
                    <TableCell sx={{ minWidth: 120 }}>Verified</TableCell>
                    <TableCell sx={{ minWidth: 200 }}>Block</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {search(TABLE_DATA)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { id, firstName, lastName, email, phone, profileImage, isBlock, verified } = row;

                      return (
                        <TableRow key={id}>
                          <TableCell>
                            <Avatar
                              style={{ borderRadius: 500 }}
                              alt={profileImage}
                              src={BaseURLImage + profileImage}
                            />
                          </TableCell>
                          <TableCell>{firstName}</TableCell>
                          <TableCell>{lastName}</TableCell>
                          <TableCell>{email}</TableCell>
                          <TableCell>{phone}</TableCell>
                          <TableCell align="left">
                            <Label color={(verified === 0 && 'error') || 'success'}>
                              {sentenceCase(verified === 0 ? 'No' : 'Yes')}
                            </Label>
                          </TableCell>
                          <TableCell align="left">
                            <Label color={(isBlock === 1 && 'error') || 'success'}>
                              {sentenceCase(isBlock === 0 ? 'Active' : 'Block')}
                            </Label>
                          </TableCell>

                          <TableCell align="right">
                            <UserTablePopUpMenu
                              isBlock={isBlock}
                              onIsVerifiedClick={() => setUserVerifiedState(id, verified)}
                              onIsBlockClick={() => setUserActiveState(id, isBlock)}
                              startChatWithUserClick={() => checkUserExistOrNot(id)}
                              onDelete={() => handleDeleteUser(id)}
                              userID={id}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{TABLE_DATA}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={search(TABLE_DATA).length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </div>
    </>
  );
}
