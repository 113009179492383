import React, { useState, useContext } from 'react';
import firebase from 'firebase/app';
import { Grid, CircularProgress } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/Attachment'; // Importing attachment icon from Material-UI
import SendIcon from '@mui/icons-material/Send'; // Importing send icon from Material-UI
import { ChatContext } from '../../context/ChatContext';
import './style.css';
import { db, storage } from '../../firebase';
import { BaseURL } from '../../common/Base_Url';

const Input = () => {
  const { data } = useContext(ChatContext);
  const [text, setText] = useState('');
  const [image, setImage] = useState(null);
  const [imageFileName, setImageFileName] = useState(''); // State to hold the name of the selected image
  const [isUploading, setIsUploading] = useState(false);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setImageFileName(file.name); // Set the name of the selected image
    }
  };

  const sendNotification = (userId, title, description) => {
    const TOKEN = localStorage.getItem('currentUserAccessToken');

    fetch(`${BaseURL}sendPushNotificationToUser`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: `Bearer ${TOKEN}`,
      },
      body: JSON.stringify({
        userId,
        title,
        description,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });
  };

  const storeNotification = (USER_ID, title, description) => {
    const TOKEN = localStorage.getItem('currentUserAccessToken');

    fetch(`${BaseURL}createNotification`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: `Bearer ${TOKEN}`,
      },
      body: JSON.stringify({
        user_id: USER_ID,
        title,
        description,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {})
      .catch((error) => {
        alert(JSON.stringify(error));
      });
  };

  const handleSend = async () => {
    if (text === '' && !image) {
      return;
    }

    const key = data.chatId;

    const serverdate = firebase.firestore.FieldValue.serverTimestamp();

    if (image) {
      setIsUploading(true);
      try {
        const storageRef = storage.ref();
        const imageRef = storageRef.child(`images/${image.name}`);
        const snapshot = await imageRef.put(image);
        const downloadURL = await snapshot.ref.getDownloadURL();

        const mymsg = {
          created_at: serverdate,
          message: text,
          image: downloadURL,
          receiver_id: `${data.user?.participants[0].id}`,
          sender_id: 17,
        };
        await db.collection('chat_rooms').doc(key).collection('messages').add(mymsg);
        await db.collection(`chat_rooms`).doc(key).update({
          last_message: text,
          updated_at: serverdate,
        });

        sendNotification(`${data.user?.participants[0].id}`, '(Chat) Nova Auto Spa', 'Te ha enviado una imagen');
        storeNotification(`${data.user?.participants[0].id}`, '(Chat) Nova Auto Spa', 'Te ha enviado una imagen');
        setText('');
        setImage(null);
        setImageFileName(''); // Remove the image name after sending
      } catch (error) {
        console.error('Error uploading image:', error);
      } finally {
        setIsUploading(false);
      }
    } else {
      const mymsg = {
        created_at: serverdate,
        message: text,
        receiver_id: `${data.user?.participants[0].id}`,
        sender_id: 17,
      };
      await db.collection('chat_rooms').doc(key).collection('messages').add(mymsg);
      await db.collection(`chat_rooms`).doc(key).update({
        last_message: text,
        updated_at: serverdate,
      });
      sendNotification(`${data.user?.participants[0].id}`, '(Chat) Nova Auto Spa', text);
      storeNotification(`${data.user?.participants[0].id}`, '(Chat) Nova Auto Spa', text);
      setText('');
    }
  };

  return (
    <div className="input">
      <input type="text" placeholder="Type something..." value={text} onChange={(e) => setText(e.target.value)} />

      <div>
        <Grid container spacing={3}>
          <Grid item xs={9} lg={9} sm={9} md={9} style={{ display: 'flex', alignItems: 'center' }}>
            <label htmlFor="image-input" style={{ display: 'flex', alignItems: 'center' }}>
              <AttachFileIcon style={{ cursor: 'pointer', width: 25, height: 25 }} /> {/* Attachment icon */}
              {imageFileName && <div style={{ marginLeft: 5, marginRight: 25 }}>Selected</div>}
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: 'none' }}
              id="image-input"
            />
          </Grid>
          <Grid item xs={3} lg={3} sm={3} md={3}>
            <button
              style={{
                backgroundColor: 'transparent',
                borderWidth: 0,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={handleSend}
              disabled={isUploading}
            >
              {isUploading ? (
                <CircularProgress size={24} style={{ marginRight: 10 }} /> // Show loader if uploading
              ) : (
                <SendIcon style={{ marginRight: 100, marginLeft: -50 }} /> // Send icon
              )}
            </button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Input;
