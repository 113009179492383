import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid, Card, Stack, Typography, TextField, OutlinedInput } from '@mui/material';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import axios from 'axios';
import { Oval } from 'react-loader-spinner';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';

import { BaseURL } from '../common/Base_Url';

export default function Notifications() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const handleClick = () => {
    if (!title) {
      alert('Please enter your title');
      return;
    }
    if (!description) {
      alert('Please enter your description');
      return;
    }

    // Ask for confirmation
    if (window.confirm('Are you sure you want to send this notification to all users?')) {
      setLoading(true);

      const TOKEN = localStorage.getItem('currentUserAccessToken');

      fetch(`${BaseURL}sendPushNotificationToAllUsers`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
          Authorization: `Bearer ${TOKEN}`,
        },
        body: JSON.stringify({
          title,
          description,
        }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          setLoading(false);
          setTitle('');
          setDescription('');
          alert('Notification successfully sent to all users.');
        })
        .catch((error) => {
          setLoading(false);
          setTitle('');
          setDescription('');
          alert(JSON.stringify(error));
        });
    }
  };

  return (
    <>
      <Helmet>
        <title> Notifications | Nova Auto Spa </title>
      </Helmet>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Notifications
        </Typography>
        {loading === true ? (
          <Oval
            height={30}
            width={30}
            color="#0F70F5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={'true'}
            ariaLabel="oval-loading"
            secondaryColor="#2e3a59"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        ) : null}
      </Stack>

      <Card style={{ padding: 20 }}>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6} md={6}>
            <TextField
              style={{ width: '100%', marginTop: 20 }}
              multiline
              maxRows={10}
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              label="Title"
            />
            <TextField
              style={{ width: '100%', marginTop: 20 }}
              multiline
              maxRows={10}
              name="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              label="Description"
            />

            <LoadingButton
              onClick={() => handleClick()}
              style={{ width: 220, height: 55, marginTop: 20 }}
              fullWidth
              size="large"
              variant="contained"
              loading={loading}
              disabled={loading} // Disable the button when loading is true
            >
              {loading ? 'Sending...' : 'Send To All'}
            </LoadingButton>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
