import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyDPOO_8MJlY5349CISg4KzwoTUurw_pOFw',
  authDomain: 'nova-auto-spa.firebaseapp.com',
  projectId: 'nova-auto-spa',
  storageBucket: 'nova-auto-spa.appspot.com',
  messagingSenderId: '1030691783716',
  appId: '1:1030691783716:web:6ea843fa25dfc2a640c4b1',
  measurementId: 'G-XW8EWEB7XV',
};

firebase.initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const db = firebase.firestore();
export const storage = firebase.storage();
